import path from "path"
import { createUrl, PathRegExp } from "@marvinh/path-to-regexp"

import type { Nullish, ParseUrlParams } from "~/@types/generics"
import pathsMapping from "~/config/paths-mapping.json"
import { DEFAULT_LOCALE } from "~/lib/i18n/constants"
import { getLang } from "~/lib/i18n/utils/get-i18n"
import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import { isLocale } from "~/lib/i18n/utils/is-locale"
import { getPropertyFromGID } from "~/lib/shopify/utils/id"
import { SB_PAGES, type SbPagesComponent } from "~/lib/storyblok/constants"
import type { SbLinkFromSchema, ShopifyLinkFromSchema } from "~/components/ui/Link/_data/schema"
import { isSbLink, isShopifyLink } from "~/components/ui/Link/utils/is-link"

export const isProductPage = "products"

export function hrefResolver(link: SbLinkFromSchema | ShopifyLinkFromSchema, locale: Nullish<string>) {
  const processedLocale = getProcessedLocale(locale)

  if (isShopifyLink(link)) {
    const property = getPropertyFromGID(link.id)
    switch (property) {
      case "Product":
        return getPath(processedLocale, "products/:slug", { slug: link.handle })
      case "Collection":
        return getPath(processedLocale, "collections/:slug", { slug: link.handle })
      default:
        return null
    }
  }

  if (isSbLink(link)) {
    if (!link.story) {
      return null
    }

    const translatedSlug = link.story.translated_slugs.find((slug) => slug.lang === getLang(processedLocale))

    const pageComponent = link.story.content.component as SbPagesComponent

    let processedSlug = link.story.slug

    switch (pageComponent) {
      case "home_page":
        return getPath(processedLocale, "/", {})
      case "universal_page":
        processedSlug = translatedSlug?.path.replace(SB_PAGES[pageComponent].rootSlug, "") ?? link.story.slug
        return getPath(processedLocale, "pages/:slug", { slug: processedSlug })
      case "collection_page":
        processedSlug = translatedSlug?.path.replace(SB_PAGES[pageComponent].rootSlug, "") ?? link.story.slug
        return getPath(processedLocale, "collections/:slug", { slug: processedSlug })
      case "legals_page":
        processedSlug = translatedSlug?.path.replace(SB_PAGES[pageComponent].rootSlug, "") ?? link.story.slug
        return getPath(processedLocale, "legals/:slug", { slug: processedSlug })
      case "blog_home_page":
        return getPath(processedLocale, "le-journal", {})
      case "blog_article_page":
        processedSlug = translatedSlug?.path.replace(SB_PAGES[pageComponent].rootSlug, "") ?? link.story.slug
        return getPath(processedLocale, "le-journal/:slug", { slug: processedSlug })
      case "login_page":
        return getPath(processedLocale, "account/login", {})
      case "register_page":
        return getPath(processedLocale, "account/register", {})
      case "enable_account_page":
        return getPath(processedLocale, "account/enable-account", {})
      case "reset_password_page":
        return getPath(processedLocale, "account/reset-password", {})
      case "forgot_password_page":
        return getPath(processedLocale, "account/forgot-password", {})
      case "account_addresses_page":
        return getPath(processedLocale, "/account/addresses", {})
      case "account_informations_page":
        return getPath(processedLocale, "/account", {})
      case "account_orders_page":
        return getPath(processedLocale, "/account/orders", {})
      case "search_page":
        return getPath(processedLocale, "/search", {})
      default:
        return null
    }
  }
}

export function getPath<Route extends string, Params extends ParseUrlParams<Route>>(
  locale: Nullish<string>,
  route: Route,
  params: Params
) {
  const processedLocale = isLocale(locale) ? locale : DEFAULT_LOCALE

  const processedRoute =
    (pathsMapping as Record<string, Record<string, { source: string; destination: string }>>)?.[route]?.[
      processedLocale
    ]?.source ?? route

  const pathRegExp = new PathRegExp(processedRoute)

  const endPath = createUrl(pathRegExp, params)

  return path.join("/", processedLocale, endPath)
}
