"use client"

import clsx from "clsx"

import { isShopifyPNGImage } from "~/lib/shopify/utils/is-shopify-png-image"
import { isStoryblokPNGImage } from "~/lib/shopify/utils/is-storyblok-png-image"
import { AbstractImage, type AbstractImageProps } from "~/components/abstracts/Image"
import imageLoader from "~/components/abstracts/Image/loader"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"
import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

export interface ImageProps extends Omit<AbstractImageProps, "screens" | "ratio" | "objectFit"> {
  ratioClassName?: string
  noBackground?: boolean
  ratio?: Sprinkles["ratio"]
  objectFit?: Sprinkles["objectFit"] | (({ isPNG }: { isPNG: boolean }) => Sprinkles["objectFit"])
  imageHeight?: Sprinkles["height"]
  sprinklesCss?: Sprinkles
}

export function Image({
  className,
  noBackground = false,
  imageClassName,
  ratio,
  ratioClassName,
  objectFit = "cover",
  imageHeight = "auto",
  sprinklesCss,
  ...props
}: ImageProps) {
  const isShopifyPNG = isShopifyPNGImage(props.src)
  const isStoryblokPNG = isStoryblokPNGImage(props.src)

  const isPNG = isShopifyPNG || isStoryblokPNG

  return (
    <AbstractImage
      className={clsx(
        sprinkles({
          position: "relative",
          zIndex: 0,
          ...(sprinklesCss ?? {}),
        }),
        className,
        {
          [css.Bg]: !noBackground && isPNG,
        }
      )}
      imageClassName={clsx(
        sprinkles({
          maxWidth: "100%",
          height: imageHeight,
          objectFit: typeof objectFit === "function" ? objectFit({ isPNG }) : objectFit,
        }),
        imageClassName
      )}
      placeholderClassName={css.Bg}
      loader={imageLoader}
      draggable={false}
      screens={breakpoints}
      ratio={ratio ? sprinkles({ ratio }) : undefined}
      ratioClassName={ratioClassName}
      {...props}
    />
  )
}

export type { Sizes } from "~/components/abstracts/Image"
export type { imageLoader }
