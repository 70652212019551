import { useMemo } from "react"

import { logger } from "~/utils/logger"

import type { Breakpoint, Screens } from "~/styles/variables/breakpoints"

export interface Size {
  breakpoint?: number | Breakpoint
  ratio: number | `${number}px`
}

export type Sizes = Size[]

export function vw(ratio: number) {
  return `${Math.floor(ratio * 100)}vw`
}

export function vwWithMaxWidth(ratio: number | `${string}px`, bp?: `${string}px`) {
  const processedRatio = typeof ratio === "string" ? ratio : vw(ratio)

  return ratio ? `${bp ? `(min-width: ${bp}) ${processedRatio}` : `${processedRatio}`}` : ""
}

export function useSizesFromBreakpoints(sizesFromBreakpoints: Sizes, src: string, screens: Screens) {
  const processedSizes = useMemo(() => {
    if (sizesFromBreakpoints?.length > 0) {
      const main = sizesFromBreakpoints.find((size) => !size.breakpoint)
      if (!main) {
        logger.warn(`[Image] you didn't specify a main size without breakpoint for ${src}`)
      }

      return sizesFromBreakpoints
        .reduce<string[]>((acc, { breakpoint, ratio }) => {
          if (!breakpoint) {
            return acc
          }

          // Breakpoint string from theme like 'md' or 'lg'
          if (typeof breakpoint === "string" && screens[breakpoint]) {
            acc.push(vwWithMaxWidth(ratio, `${screens[breakpoint]}px`))
          }

          // Breakpoint number like 1400
          else if (typeof breakpoint === "number") {
            acc.push(vwWithMaxWidth(ratio, `${breakpoint}px`))
          }

          return acc
        }, [])
        .concat(main ? [vwWithMaxWidth(main.ratio)] : [])
        .join(", ")
    }
  }, [sizesFromBreakpoints, screens, src])

  return processedSizes
}
