export const breakpoints = {
  base: 0,
  "4xs": 300,
  "3xs": 370,
  "2xs": 480,
  xs: 570,
  sm: 640,
  md: 760,
  l: 930,
  lg: 1020,
  xl: 1280,
  "2xl": 1440,
  max: 1940,
} as const

type Screens = typeof breakpoints
type Breakpoint = keyof typeof breakpoints

export { type Breakpoint, type Screens }
