import { HOST_NAME } from "~/lib/constants"

const URL_PATH_REGEX = /(\/[0-9].*\?|$)/g

interface IsInternalUrl {
  status: boolean
  pathname: string
}

/**
 * It checks if the given value is a URL that points to the same host as the current page
 * @param {string} value - The URL to check
 * @returns A boolean value.
 */
export default function isInternalUrl(value: string): IsInternalUrl {
  try {
    const url = new URL(value)
    return { status: url.hostname.includes(HOST_NAME), pathname: url.pathname }
  } catch {
    return { status: URL_PATH_REGEX.test(value), pathname: value }
  }
}
