import type { Nullish } from "~/@types/generics"
import type { LinkFromSchema } from "~/components/ui/Link/_data/schema"
import { hrefResolver } from "~/components/ui/Link/utils/href-resolver"
import isInternalUrl from "~/utils/is-internal-url"

export type TLink = {
  href: string
  rel: string
  isExternal?: boolean
  target?: "_blank" | "_self"
  obfuscate: boolean
}

export const defaultLink = { href: "/", rel: "noopener", obfuscate: false }

export default function serializeLink(
  link: LinkFromSchema,
  locale: Nullish<string>,
  obfuscate?: boolean
): TLink | null {
  if ("linktype" in link && link.linktype === "url") {
    const { status } = isInternalUrl(link.url)
    const rel = status ? "noopener" : "noopener nofollow"

    return {
      href: link.url,
      isExternal: true,
      target: link.target ?? "_self",
      rel: rel,
      obfuscate: obfuscate ?? defaultLink.obfuscate,
    }
  }

  const href = hrefResolver(link, locale)

  return href
    ? {
        href,
        rel: defaultLink.rel,
        obfuscate: obfuscate ?? defaultLink.obfuscate,
      }
    : null
}
